.App {
  text-align: center;
}

img {
  margin: 20px 0px;
}

p {
  margin: 5px 0px;
}
h1 {
  margin: 0px;
}

.pgh {
  width: 50vw;
}

.App-header {
  background-color: #95B8D1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 50px 0px;
}

ul {
  list-style: none;
  padding: 0px;
}

li {
  padding: 20px 0px;
}

h4 {
  margin: 15px 0px;
}

.details {
   text-align: left;
   align-items: center;
   display: inline-flex;
   flex-direction: column;
   width: 100%;
}

.detail2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.rsvp-button-group {
  width: 100%;
  margin: 20px 0px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

input {
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px;
}

button {
  border-radius: 10px;
  margin: 25px;
  height: 50px;
  font-size: 24px;
}

.rsvps {
  width: 50%;
  display: inline-flex;
}

.rsvps li {
  padding-top: 0px;
  padding-bottom: 0px;
}

.agenda li {
  display: flex;
  flex-direction: column;
}

.agenda iframe {
  margin: 0 auto;
}

.detail2 .detail3 {
  display: inline-flex;
  padding: 0px 10px;
}

h3 {
  text-align: center;
  margin-bottom: 0px;
}

.detail3 {
  line-height: 24px;
}


@media only screen and (max-width: 390px) {
  iframe {
    width: 75%;
  }

  .rachel {
    width: 45vw;
  }

  .pgh {
    width: 70vw;
  }

  input {
    width: 50%;
  }

  ul {
    width: 95%;
  }

  .body {
    margin: 0px 15px;
  }

  .rsvps {
    width: 75%;
    flex-direction: column;
  }

  .button-wrapper {
    width: 50%;
    display: inline-flex;
  }

  button {
    width: 50%;
  }

  .button-wrapper {
    width: 100%;
  }

  .details {
    flex-direction: column;
  }

  .detail2 {
    width: 100%;
  }

  .detail3 {
    width: 100%;
  }

}

@media only screen and (min-width: 391px) {
  .rachel {
    width: 20%;
  }

  rsvps.ul {
    width: 50%;
    margin: 0px 10px;
    display: block;
  }

  .things {
    width: 75%;
    text-align: left;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .agenda {
    display: inline-flex;
    flex-direction: column;
    text-align: left;
  }

  .body {
    margin: auto;
  }

  button {
    width: 100%;
  }

  .button-wrapper {
    width: 50%;
    display: inline-flex;
  }
}
